<template>
  <div>
    <div class="h-48 fill_all w-full fixed bg-gray2 opacity-70 transition filter blur" v-if="!ShowErr"></div>
    <div v-if="!ShowErr && !showSuccessPopup">
      <div class=" flex justify-center items-center p-0 h-screen px-4">
        <div class="bg-white card rounded card_design w-full p-6 text-center">
            <div class="heading-4 text-gray4 p-2">
              Please click here to confirm your dis-association from <span class="font-semibold text-primary">{{disAccDetail.organizationName}}</span>
            </div>
            <div class="py-5">
              <Button class=" h-10 w-96" :btnSize="'medium'" :textColor="'white'" :btnColor="'error'" :btnText="'Dis-Association'" @buttonAction="loginActionCall()"/>
          </div>
        </div>
      </div>
    </div>
    <div  v-if="ShowErr && firstLoading && linkExp && !showSuccessPopup">
      <div class="bg-gray-200 w-full px-2 md:px-0 h-screen flex items-center justify-center">
        <div class="bg-white border border-gray-200 flex flex-col items-center justify-center px-4 md:px-8 lg:px-20 py-8 rounded-lg shadow-2xl">
            <p class="text-7xl md:text-7xl lg:text-7xl font-bold tracking-wider text-red-300"><i class="fa-solid fa-circle-exclamation fa-fade"></i></p>
            <p class="text-gray-500 mt-3 py-2 border-y-2 text-center">Link Expired, Please Get New Link</p>
        </div>
      </div>
    </div>
    <div  v-if="ShowErr && firstLoading && !linkExp && !showSuccessPopup">
      <div class="bg-gray-200 w-full px-2 md:px-0 h-screen flex items-center justify-center">
        <div class="bg-white border border-gray-200 flex flex-col items-center justify-center px-4 md:px-8 lg:px-20 py-8 rounded-lg shadow-2xl">
            <p class="text-7xl md:text-7xl lg:text-7xl font-bold tracking-wider text-gray-300"><i class="fa-solid fa-triangle-exclamation fa-fade"></i></p>
            <p class="text-gray-500 mt-3 py-2 border-y-2 text-center">Please Check Your Link and Try Again Later</p>
        </div>
      </div>
    </div>
    <div v-if="showSuccessPopup">
      <div class="bg-gray-200 w-full px-2 md:px-0 h-screen flex items-center justify-center">
        <div class="bg-white border border-gray-200 flex flex-col items-center justify-center px-4 md:px-8 lg:px-20 py-8 rounded-lg shadow-2xl">
            <p class="text-7xl md:text-7xl lg:text-7xl font-bold tracking-wider text-green-400"><i class="fa-solid fa-check fa-fade"></i></p>
            <p class="text-gray-500 mt-3 py-2 border-y-2 text-center">Dis-association Successfully</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Button from '@/View/components/globalButton.vue'
import MyJobApp from '@/api/MyJobApp.js'
export default {
  components: {
    Button,
  },
  data () {
    return {
      showSuccessPopup: false,
      disAccDetail: {},
      linkExp: false,
      ShowErr: true,
      firstLoading: false,
      emailValid: false,
    }
  },
  beforeMount () {},
  created () {},
  mounted () {
    this.getHashDetail()
  },
  methods: {
    getHashDetail () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.HashDisAssociationLink(
        this.$route.params.hashId,
        response => {
          this.disAccDetail = response.Data !== null ? response.Data : {}
          let TodayDate = new Date().getTime()
          let getDate = new Date(this.disAccDetail.requestedOn).getTime()
          var hours = Math.abs(TodayDate - getDate) / 36e5;
          if (hours > 24) {
            this.ShowErr = true
            this.linkExp = true
            this.firstLoading = true
            document.title = 'Unvalid Link' 
          } else{
            this.ShowErr = false
            document.title = 'Dis-Association: ' + this.disAccDetail.organizationName
          }
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          if (error.message === 'No Record Found') {
            this.ShowErr = true
            this.linkExp = false
            this.firstLoading = true
          }
          this.ShowErr = true
          this.linkExp = false
          this.firstLoading = true
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    loginActionCall () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.HashDisAssociationLinkApprove(
        this.$route.params.hashId,
        response => {
          console.log('response', response)
          this.showSuccessPopup = true
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: 'Dis-association Successfully', color: 'success'})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
  },
  beforeDestroy () {},
  computed: {
  }
}
</script>
<style>
.card_design {
  max-width: 700px;
}

</style>
